import React from "react"

import Layout from "../../components/layout"
import Hero from "../../components/hero"
import PageContent from "../../page-views/services/induction-site-services"
import BgImage from "../../assets/images/2017/09/pipe-stack-slide.jpg"

const InductionSiteServicesPage = () => {
  const title = "Induction Site Services"
  const content =
    "In-service unfreezing, dewaxing, heat treatment, rubber vulcanization"

  return (
    <Layout>
      <Hero title={title} content={content} bgImage={BgImage} />
      <PageContent />
    </Layout>
  )
}

export default InductionSiteServicesPage
