import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          In-situ induction heating services comprise onshore and offshore
          capabilities for specialised heating applications including:
        </p>
        <ul style={{ listStyle: "circle" }}>
          <li>in service unfreezing/dewaxing pipelines</li>
          <li>in service pipe and weldment preheat for hot tapping</li>
          <li>weld heat treatments;</li>
        </ul>
        <p>Thermal coating applications including:</p>
        <ul>
          <li>rubber vulcanization</li>
          <li>FBE coating</li>
          <li>induction thermal expansion</li>
          <li>swaging</li>
          <li>freeing</li>
        </ul>
        <p>
          Give us a call <a href="tel:0393151244">03 9315 1244</a> for more
          information about our induction site services.
        </p>
      </div>
    </div>
  )
}

export default PageContent
